.btn {
  border-radius: 4px;
  line-height: 16px;
  box-sizing: border-box;
  @apply uppercase;
  &.large {
    padding: 12px 120px;

    &.outlined {
      padding: 11px 120px;
    }
  }

  &.medium {
    padding: 12px 56px;

    &.outlined {
      padding: 11px 56px;
    }
  }

  &.small {
    padding: 12px 32px;

    &.outlined {
      padding: 11px 32px;
    }
  }

  &.extra_small {
    padding: 10px 24px;
    line-height: 16px;

    &.outlined {
      padding: 9px 24px;
    }
  }

  &.contained {
    @apply bg-primary-500;
    @apply text-white
  }

  &.outlined {
    border: 1px solid var(--primary-500);
    @apply text-primary-500;
    &[class~="tertiary"] {
      border: 1px solid var(--tertiary-600);
      @apply text-tertiary-600;
    }
  }

  &.disabled {
    @apply bg-disabled #{!important};
    &.outlined {
      background-color: transparent !important;
      @apply border-disabled text-disabled #{!important};
    }
  }

  &.transparent {
    border: none;
    background-color: transparent;
    color: transparent;
    outline: none;
    @apply text-secondary-900;
  }

  &.text {
    @apply text-headings;
    @apply font-semibold;
    font-size: 14px;
    padding: 8px 10px;
  }

  svg {
    vertical-align: top;
    height: 16px;
    width: 16px;
  }
}
