@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  position: fixed;
  inset: 0;
  z-index: 99999;
  width: 100px;
  height: 100px;
  margin: auto;
  @apply xs:w-16 hidden first:block;
  svg {
    animation: spin 1.5s linear infinite;
    width: 100%;
    height: 100%;
  }

  &:before {
    content: '';
    display: block;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 40%;
    @apply bg-grey2;
  }
}
