
h1 {
  @apply text-8xl;
  // 95px
  letter-spacing: -1.5px;
}

h2 {
  @apply text-3xl lg:text-5xl xl:text-6xl;
  // 59px
  letter-spacing: -0.5px;
}

h3 {
  @apply text-4xl;
  @apply sm:text-5xl;
  //48px
  letter-spacing: 0;
}

h4 {
  font-size: 1.2rem;
  @apply sm:text-3xl;
  // 34px
  letter-spacing: -0.2px;
}

h5 {
  @apply text-2xl;
  //24px
  letter-spacing: 0;
}

h6 {
  @apply text-lg sm:text-xl;
  // 20px
  letter-spacing: 0.15px;
}

h1, h2, h3, h3, h4 {
  @apply font-bold;
}

h1, h2, h3, h3, h4, h5, h6 {
  @apply text-headings;
}

h5, h6 {
  @apply font-semibold;
}

.h7 {
  font-size: 18px;
  @apply font-semibold;
}

button {
  @apply font-semibold;
}

.subtitle1 {
  @apply font-semibold;
  @apply text-sm sm:text-base;
  // 16px
  letter-spacing: 0.15px;
}

.subtitle2 {
  @apply font-semibold;
  @apply text-sm;
  // 14px
  letter-spacing: 0.1px;
}


.body1 {
  @apply text-base;
  // 16px
  letter-spacing: 0.5px;
}

.body2 {
  @apply text-xs sm:text-sm;
  // 14px
  letter-spacing: 0.25px;
}

.caption {
  @apply text-xs;
  // 12px
  letter-spacing: 0.40px;
}

.overline {
  @apply text-xs;
  //12px
  letter-spacing: 1.5px;
}


