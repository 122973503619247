.MuiTextField-root {
  .MuiInputLabel-root {
    color: var(--body) !important;
    &.Mui-error {
      color: var(--functional-red) !important;
    }

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.38) !important;
     }
  }

  .MuiInputBase-root {
    color: var(--body);

    .MuiInputBase-input {
      //@apply hlg:py-2.5;
      &.Mui-disabled {
        cursor: not-allowed;
       }
    }

    &:hover fieldset {
      border-color: var(--primary-500) !important;
    }

    fieldset {
      border-color: var(--primary-500) !important;
      border-width: 1px !important;
    }

    &.Mui-error {
      fieldset {
        border-color: var(--functional-red) !important;
      }
    }
  }

  .MuiFormHelperText-root.Mui-error {
    @apply text-functional-red;
  }

  &.tertiary {
    .MuiInputBase-root {
      &:hover fieldset {
        border-color: var(--tertiary-600) !important;
      }

      fieldset {
        border-color: var(--tertiary-600) !important;
        border-width: 1px !important;
      }
    }
  }

  &.secondary {
    .MuiInputBase-root {
      &:hover fieldset {
        border-color: var(--secondary-700) !important;
      }

      fieldset {
        border-color: var(--secondary-700) !important;
        border-width: 1px !important;
      }

      &.Mui-error {
        fieldset {
          border-color: var(--functional-red) !important;
        }
      }
    }
  }

  &.grey2 {
    .MuiInputBase-root {
      &:hover fieldset {
        border-color: var(--grey2) !important;
      }

      fieldset {
        border-color: var(--grey2) !important;
        border-width: 1px !important;
      }
    }
  }

  &.no-outline {
    .MuiInputBase-root {
      fieldset {
        border: none !important;
      }
    }
  }
}

.MuiModal-root.modal {
  &.zeroPadding {
    .MuiDialogContent-root {
      @apply p-0 m-0 #{!important};
    }
  }

  .MuiBackdrop-root {
    backdrop-filter: blur(1px);
    opacity: 0.95 !important;
    @apply bg-headings;
  }

  .MuiDialog-container {
    .MuiPaper-root {
      &.MuiDialog-paperWidthXs {
        @apply sm:max-w-md;
      }

      @apply text-center;
      border-radius: 10px;
      @apply shadow-dialog;
      @apply text-body;
      .MuiDialogContent-root {
        @apply sm:mx-2.5;
        overflow-x: hidden;
      }
    }

    .MuiDialogTitle-root {
      background: linear-gradient(180deg, rgba(0, 147, 164, 0.59) 9.83%, var(--secondary-400) 66.81%);
    }

    //&::-webkit-scrollbar {
    //  display: none;
    //}
    //
    //-ms-overflow-style: none; /* IE and Edge */
    //scrollbar-width: none; /* Firefox */
    overflow-y: overlay;
  }
}

.MuiMenu-paper {
  border-radius: 5px;
  @apply shadow-hovercard;
  @apply mt-2.5;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
  overflow: visible;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 14px;
    width: 10px;
    height: 10px;
    background-color: rgb(255, 255, 255);
    transform: translateY(-50%) rotate(45deg);
    z-index: 0;
  }
}

//stepper
.MuiStepper-root {
  .MuiStepLabel-root {
    &.Mui-disabled {
      .MuiStepLabel-iconContainer {
        svg {
          @apply text-background;
          text {
            fill: var(--grey1);
          }
        }
      }

      .MuiStepLabel-labelContainer {
        span.MuiStepLabel-label {
          @apply text-body font-semibold;
        }
      }
    }

    .MuiStepLabel-iconContainer {
      svg {
        @apply text-primary-500;
        font-size: xx-large;

        text {
          @apply font-semibold;
        }
      }
    }

    .MuiStepLabel-labelContainer {
      span.MuiStepLabel-label {
        @apply xs:text-[12px] font-bold text-primary-500 mt-1.5;
      }
    }
  }

  .MuiStep-root {
    .customConnector {
      left: calc(-50% + 16px);
      right: calc(50% + 16px);
      @apply absolute top-3 h-1.5 bg-background;
    }

    .MuiStepConnector-root {
      &.Mui-disabled {
        .MuiStepConnector-line {
          border-color: var(--background);
        }
      }

      left: calc(-50% + 16px);
      right: calc(50% + 16px);

      .MuiStepConnector-line {
        border-top-width: 6px;
        border-color: var(--primary-500);
      }
    }
  }

  &.progress {

    .MuiStepLabel-root {
      &.Mui-disabled {
        .MuiStepLabel-iconContainer {
          svg {
            @apply text-grey1;
            text {
              fill: var(--white);
            }
          }
        }

        .MuiStepLabel-labelContainer {
          span.MuiStepLabel-label {
            @apply text-disabled;
          }
        }
      }

      .MuiStepLabel-label {
        @apply xs:text-[9px] visible;
      }

      .MuiStepLabel-labelContainer {
        span.MuiStepLabel-label {
          @apply font-semibold text-body mt-1.5;
        }
      }
    }

    .MuiStep-root {
      .MuiStepConnector-root {
        &.Mui-disabled {
          .MuiStepConnector-line {
            border-color: var(--grey1);
          }
        }

        .MuiStepConnector-line {
          border-top-width: 1.2px;
          border-color: var(--primary-500);
          margin-top: 3px;
        }
      }
    }
  }
}

.MuiMenuItem-root.option {
  @apply text-body;
  &.Mui-selected {
    @apply bg-primary-50;
  }
}


// datepicker
.MuiCalendarPicker-root {
  .MuiButtonBase-root {
    &.MuiPickersDay-today {
      border-color: var(--primary-500);
    }

    &.Mui-selected {
      background: var(--primary-500);

      &:focus, &:hover {
        background: var(--primary-500);
      }
    }
  }
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    @apply p-2.5 bg-background text-body leading-[140%] text-xs shadow-hovercard;
    //filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
    border-radius: 10px;
    letter-spacing: 0.25px;

    .MuiTooltip-arrow {
      @apply text-background;
    }

    &.MuiTooltip-tooltipPlacementTop {
      right: -28px;

      .MuiTooltip-arrow {
        left: -28px !important;
      }
    }
  }
}

.MuiCheckbox-root.checkbox {
  &.Mui-checked {
    @apply text-primary-500;
  }
}

.MuiAvatarGroup-root {
  .MuiAvatar-colorDefault {
    @apply bg-background text-headings font-semibold;
  }
}

.MuiAvatar-rounded {
  @apply rounded-[10px] #{!important};
}

.MuiAvatar-root {
  &.avatar {
    @apply bg-primary-800 text-white text-[16px] font-semibold;
  }
}

.MuiList-root {
  //padding: 0 !important;
}

// mui tabs
.MuiTabs-root {
  &.small {
    .MuiButtonBase-root {
      font-size: 14px;
    }
  }

  .MuiTab-root {
    @apply text-body text-lg font-semibold capitalize;
    &.Mui-selected {
      @apply text-primary-500;
    }
  }

  .MuiTabs-indicator {
    @apply bg-primary-500;
  }
}

.MuiSlider-root.slider {
  @apply text-secondary-900;
  .MuiSlider-valueLabel {
    @apply bg-white rounded-[3px] text-secondary-900 font-bold shadow-dialog;
  }
}

.MuiDivider-root {
  @apply border-grey1 #{!important};
}

.MuiDrawer-root {
  z-index: 1400 !important;
}

.MuiAccordion-root {
  &.MuiAccordion-rounded {
    border-radius: 10px !important;

  }

  .MuiAccordionSummary-root {
    border-radius: 10px;
    transition: all 0.6s ease-in-out;
    min-height: 0;

    .MuiAccordionSummary-content {
      margin: 0 !important;
    }

    &.Mui-expanded {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      min-height: 0;

      .MuiAccordionSummary-content {
        margin: 0 !important;
      }
    }
  }

  &.secondary {
    .MuiAccordionSummary-root {
      @apply bg-secondary-700;
    }
  }

  &.primary {
    .MuiAccordionSummary-root {
      @apply bg-primary-500;
    }
  }

}


.MuiSnackbar-root.snackbar {
  @apply sm:bottom-[80px] visible;

  &.success {
    .MuiPaper-root {
      @apply bg-success-green;
    }
  }

  .MuiPaper-root {
    .MuiSnackbarContent-message {
      @apply font-semibold;
    }
  }
}

.MuiSwitch-root {
  .MuiSwitch-switchBase {
    .MuiSwitch-thumb {
      background-color: var(--grey1);
    }

    &.Mui-checked {
      & .MuiSwitch-thumb {
        background-color: var(--secondary-700);
      }

      & + .MuiSwitch-track {
        background-color: var(--background) !important;
        border: 1px solid var(--secondary-700) !important;
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.8;
          background-color: var(--background) !important;
          border: none !important;
      }
      &.Mui-disabled .MuiSwitch-thumb {
        opacity: 0.3;
    }
    }

  }

  .MuiSwitch-track {
    background-color: var(--background) !important;
    border: 1px solid var(--grey1) !important;
  }
}

.app-notifications {
  .MuiPopover-paper{
    margin-top: 20px;
    overflow: visible !important;
    margin-left: 22px;
  }
  .MuiPopover-paper::before{
    content: '';
    position: absolute;
    top: -20px;
    right: 28px;
    border-bottom: 10px solid white;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 10px solid transparent;
    z-index: 10;
  }
}

.icon-tooltip {
  pointer-events: auto;
}

.user-roles-input {
   .Mui-disabled {
    color: var(--secondary-900) !important;
    -webkit-text-fill-color: var(--secondary-900) !important;
   }
}
