.overview {
  padding: 20px;
  position: relative;
  filter: drop-shadow(0px 5.8107px 23.2428px rgba(0, 0, 0, 0.1));
  @apply mb-4 sm:mb-0;
  .background {
    background: linear-gradient(88.04deg, var(--primary-500) 27.58%, var(--secondary-300) 51.58%, var(--tertiary-400) 93.45%);
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0.1;
    border-radius: 10px;
  }
}
